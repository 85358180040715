import React, { useState, useEffect, useRef } from "react";
import {
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [clientSecret, setClientSecret] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
  const emailRef = useRef(email);
  const nameRef = useRef(name);

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '17px',
      },
    },
    hidePostalCode: true, // Hides the ZIP/postal code field
  };

  useEffect(() => {
    const checkServerHealth = async () => {
      if (!modalVisible) return; // Only run the health check if the modal is open
      try {
        const response = await fetch("https://lizzie-fox-hair.onrender.com/health");
        if (!response.ok) {
          throw new Error("Server is not responding.");
        }
        const data = await response.json();
        console.log(data.message); // Optionally log the health-check message
      } catch (err) {
        setModalVisible(false); // Close the modal if the server isn't ready
      } 
    };
  
    checkServerHealth();
  }, [modalVisible]); // This triggers when modalVisible changes

  // Load reCAPTCHA when the modal is visible
  useEffect(() => {
    if (modalVisible) {
      window.grecaptcha?.ready(() => {
        window.grecaptcha.render("g-recaptcha", {
          sitekey: "6LcgAbMqAAAAAIWFbtF-vc5xUdR_2hUHAwoIHbm-",
        });
      });
    }
    console.log("Modal Closed")
  }, [modalVisible]);

  useEffect(() => {
    emailRef.current = email;
    nameRef.current = name;
  }, [email, name]);

  // Fetch the client secret from the server
  const fetchClientSecret = async () => {
    try {
      const response = await fetch(
        "https://lizzie-fox-hair.onrender.com/create-payment-intent",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount: 1599 }),
        }
      );
      const { clientSecret } = await response.json();
      setClientSecret(clientSecret);
    } catch (error) {
      setPaymentStatus("Error fetching payment details. Please try again.");
    }
  };

  // Fetch the client secret when the modal becomes visible
  useEffect(() => {
    if (modalVisible) fetchClientSecret();
  }, [modalVisible]);

  // Check for Apple Pay or Google Pay support and set up the payment button
  useEffect(() => {
    if (stripe && clientSecret && elements) {
      const setupPaymentRequest = async () => {
        const paymentRequest = stripe.paymentRequest({
          country: "GB",
          currency: "gbp",
          total: {
            label: "Grey Blending Handbook",
            amount: 1599,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        const result = await paymentRequest.canMakePayment();
        if (result?.applePay || result?.googlePay) {
          setIsApplePayAvailable(true);

          // Avoid creating multiple payment buttons
          const buttonContainer = document.getElementById(
            "payment-request-button"
          );
          if (buttonContainer && !buttonContainer.hasChildNodes()) {
            const prButton = elements.create("paymentRequestButton", {
              paymentRequest,
            });

            prButton.mount("#payment-request-button");

            paymentRequest.on("paymentmethod", async (event) => {
              if (!emailRef.current) {
                event.complete("fail");
                setPaymentStatus("Please enter your email address.");
                return;
              }

              try {
                const { error } = await stripe.confirmCardPayment(
                  clientSecret,
                  {
                    payment_method: event.paymentMethod.id,
                  }
                );

                if (error) {
                  event.complete("fail");
                  setPaymentStatus("Payment failed: " + error.message);
                } else {
                  event.complete("success");
                  setPaymentStatus(
                    "Payment successful! An email has been sent with instructions to access your eBook."
                  );
                  setPaymentSuccess(true);

                  const password = generatePassword();
                  await sendEmailInstructions(emailRef.current, password);
                }
              } catch (err) {
                event.complete("fail");
                console.error("Error processing Apple Pay:", err);
                setPaymentStatus("Something went wrong. Please try again.");
              }
            });
          }
        } else {
          console.warn(
            "Apple Pay or Google Pay is not available on this device."
          );
          setIsApplePayAvailable(false);
        }
      };

      setupPaymentRequest();
    }

    // Cleanup the button
    return () => {
      const buttonContainer = document.getElementById("payment-request-button");
      if (buttonContainer) {
        buttonContainer.innerHTML = "";
      }
    };
  }, [stripe, clientSecret, elements]); // Only dependencies required for Apple Pay setup

  // Handle form submission for CardElement payments
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements || !clientSecret) {
      setPaymentStatus("Payment system is not ready. Please try again later.");
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setPaymentStatus("Please enter your payment details.");
      return;
    }
  
    setIsProcessing(true);
  
    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: name,
            email: email,
          },
        },
      });
  
      if (error) {
        setPaymentStatus("Payment failed: " + error.message);
      } else if (paymentIntent.status === "succeeded") {
        setPaymentStatus(
          "Payment successful! An email has been sent with instructions to access your eBook."
        );
        setPaymentSuccess(true);
  
        const password = generatePassword();
        const response = await fetch("https://lizzie-fox-hair.onrender.com/post-payment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            paymentIntentId: paymentIntent.id,
            password: password,
          }),
        });
  
        if (!response.ok) {
          throw new Error("Failed to complete the post-payment process.");
        }
        await sendEmailInstructions(email, password);
      }
    } catch (err) {
      setPaymentStatus("Something went wrong. Please try again.");
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  // Generate a random password for the user
  const generatePassword = () => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  // Send email instructions to the user
  const sendEmailInstructions = async (email, password) => {
    try {
      await fetch("https://api.emailjs.com/api/v1.0/email/send", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          service_id: "service_j1l52o7",
          template_id: "template_sy4ud3j",
          user_id: "A5uq8fgtKxlrfjWP6",
          template_params: { email, password },
        }),
      });
    } catch (err) {
      console.error("Error sending email:", err);
      setPaymentStatus("There was an issue sending your email.");
    }
  };

  return (
    <>
      <div className="promo-container">
        <div className="promo-image" onClick={() => setModalVisible(true)}>
          <img src="/images/gbhCover.png" alt="Grey Blending Handbook" />
        </div>
        <div className="promo-text">
          <h2>Discover the Art of Grey Blending</h2>
          <p>
            Are you ready to embrace your natural greys with confidence and
            ease? 🌿 <strong>The Grey Blending Handbook</strong> is your
            ultimate guide to transitioning beautifully into blended,
            low-maintenance hair. Whether you’re spotting your first silver
            strands or looking to harmonize your greys with your natural tones,
            this handbook has everything you need.
          </p>
            <p>
              Expert techniques like highlights, lowlights, balayage, and
              glossing to reduce demarcation lines.
            </p>
            <p>
              Detailed profiles to help you identify the perfect grey blending
              approach for your unique style.
            </p>
            <p>
              Insights into products to
              preserve your hair’s health and shine.
            </p>
            <p>
              Step-by-step guidance to ensure your grey blending journey is
              seamless and uniquely yours.
            </p>
          <p>
            Authored by a seasoned hair professional with 20+ years of
            experience, this handbook empowers both clients and hairdressers to
            achieve stunning, natural results.
          </p>
          <p>
            <em>
              ✨ Say goodbye to harsh regrowth lines and hello to effortless,
              radiant beauty.
            </em>
          </p>
          <p>
            <strong>
              Start your transformation today—your grey blending journey begins
              here!
            </strong>
          </p>
          <button
            className="promo-button"
            onClick={() => setModalVisible(true)}
          >
            Purchase Now
          </button>
        </div>
      </div>

      {modalVisible && !paymentSuccess && (
        <div className="overlay">
          <div className="payment-popup">
            <button
              className="close-button"
              onClick={() => setModalVisible(false)}
            >
              <div>
              ✖
              </div>
            </button>
            <form className="paymentForm" onSubmit={handleSubmit}>
              <div className="checkout-summary">
                <div className="product-details">
                  <img
                    src="/images/gbhCover.png"
                    alt="Grey Blending Handbook"
                    className="product-image"
                  />
                  <div className="product-info">
                    <h3>Grey Blending Handbook</h3>
                    <p>By Lizzie at Lizzie Fox Hair</p>
                  </div>
                </div>

                <div className="total-details">
                  <hr />
                  <div className="total-row">
                    <span>Total</span>
                    <strong>£15.99</strong>
                  </div>
                </div>

                <input
                  className="name-input"
                  type="name"
                  placeholder="Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <input
                  type="email"
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <p>Payment Details</p>
                <CardElement options={cardElementOptions}/>
                <div id="g-recaptcha" className="g-recaptcha"></div>
                <div id="payment-request-button"></div>
                <button
                  type="submit"
                  disabled={isProcessing}
                  className={`place-order-button ${
                    isProcessing ? "disabled-cursor" : ""
                  } ${email.trim() === "" ? "hidden" : ""}`}
                >
                  {isProcessing ? "Processing..." : "Place Order"}
                </button>

                {isApplePayAvailable && <div id="payment-request-button"></div>}

                {paymentStatus && <p>{paymentStatus}</p>}
              </div>
            </form>
          </div>
        </div>
      )}

      {paymentSuccess && (
        <div className="overlay">
          <div className="payment-popup" style={{ background: "white" }}>
            <button
              className="close-button"
              onClick={() => setModalVisible(false)}
            >
              ✖
            </button>
            <p>{paymentStatus}</p>
            <p>
              For terms and conditions about purchasing my Grey Blending Handbook,
              click{" "}
              <a style={{ color: "black" }} href="/terms">
                here
              </a>
              .
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutForm;
